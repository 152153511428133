/* eslint-disable no-nested-ternary */

import {
  Add,
  Edit,
  MoreVert,
  StackedBarChart,
  StackedBarChartTwoTone,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { batch, useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import ellipsis from 'text-ellipsis';
import getAllQueryObjectsForDataSource from '../../../api/getAllQueryObjectsForDataSource';
import updateDataSourceObject from '../../../api/updateDataSourceObject';
import * as types from '../../../constants/ActionTypes';
import CreateQueryDialog from './CreateQueryDialog';

const orState = {
  id: '',
  name: '',
  dateRange: '',
  selectedMetrics: [],
  selectedDimensions: [],
  startDate: '',
  endDate: '',
  dateRangeError: '',
  dimensionsFilters: [],
  sortField: null,
  limit: '',
  additionalFields: [{ name: 'powerbi', value: true }],
};
async function sendRequest(url, { arg }) {
  const tokens = await Auth.currentSession();

  return API.post('DataProviderApi', url, {
    body: arg,
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

async function getRequest(url) {
  const tokens = await Auth.currentSession();
  return API.get('DataProviderApi', url, {
    headers: {
      Authorization: `${tokens.getIdToken().getJwtToken()}`,
    },
    response: true,
    queryStringParameters: {},
  });
}

function QueryComposerDialog({ onClose = () => {} }) {
  const reduxDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [queryObjects, setQueryObjects] = useState([]);

  const { trigger, isMutating } = useSWRMutation(
    '/data-providers/query/delete',
    sendRequest
  );
  // eslint-disable-next-line no-unused-vars
  const [initialState, setInitialState] = React.useState(orState);

  const selectedDataSource = useSelector(
    (reduxState) => reduxState.selectedDataSource
  );
  const dialogs = useSelector((reduxState) => reduxState.dialogs);

  const openDialog = dialogs.showQueryComposerDialog;

  function closeDialog() {
    reduxDispatch({
      type: types.SHOW_QUERY_COMPOSER_DIALOG,
      isVisible: false,
    });
    onClose();
  }

  function createInitialState(dataLink) {
    const inState = {
      id: dataLink.id,
      name: dataLink.name,
      dateRange: dataLink.dateRangeType,
      fieldIds: dataLink.fields,
      sort: dataLink.sort,
      dimensionsFiltersObjects: dataLink.dimensionsFilters,
      selectedMetrics: [],
      selectedDimensions: [],
      startDate: dataLink.dateRange.startDate,
      endDate: dataLink.dateRange.endDate,
      dateRangeError: '',
      dimensionsFilters: [],
      sortField: null,
      limit: dataLink.limit,
      additionalFields: dataLink.additionalFields,
      integrations: dataLink.integrations,
    };
    if (
      inState.dateRange === 'CUSTOM' &&
      !inState.startDate &&
      !inState.endDate
    ) {
      inState.dateRange = undefined;
    }
    setInitialState(inState);
    setOpen(true);
  }

  async function refreshDataSource() {
    const queryObjectss = selectedDataSource
      ? await getAllQueryObjectsForDataSource(selectedDataSource.id)
      : [];

    setQueryObjects(queryObjectss);
    const isOpen = await new Promise((resolve) => {
      setOpen((o) => {
        resolve(o);
        return o;
      });
    });
    if (
      initialState.id &&
      queryObjectss.find((q) => q.id === initialState.id) &&
      isOpen
    ) {
      createInitialState(queryObjectss.find((q) => q.id === initialState.id));
    }
    if (isOpen && !initialState.id) {
      createInitialState(
        queryObjectss.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )[0]
      );
    }
  }

  React.useEffect(() => {
    // setInitialState(orState);
    refreshDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSource]);

  if (!selectedDataSource) {
    return <></>;
  }
  return (
    <>
      {open && (
        <CreateQueryDialog
          initialState={initialState}
          open={open}
          refreshDataSource={refreshDataSource}
          setInitialState={createInitialState}
          setOpen={setOpen}
        />
      )}

      <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={openDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Queries</DialogTitle>
        <DialogContent>
          {queryObjects?.length > 0 && (
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Query</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {queryObjects.map((composedQuery) => (
                    <TableRow key={composedQuery.id}>
                      <TableCell>
                        <Box display="flex" flexDirection="row">
                          <Box sx={{ mr: 1 }}>
                            <Avatar
                              color="info"
                              sx={{ bgcolor: 'white' }}
                              variant="circular"
                            >
                              <StackedBarChart color="info" />
                            </Avatar>
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography
                              color="textPrimary"
                              component="span"
                              variant="body1"
                            >
                              {composedQuery.name}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              component="span"
                              variant="body2"
                            >
                              {ellipsis(composedQuery.fields.join(','), 40)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <Button
                          color="info"
                          onClick={async () => {
                            createInitialState(composedQuery);
                          }}
                          startIcon={<Edit />}
                          sx={(theme) => ({
                            textTransform: 'none',
                            background: theme.palette.background.default,
                            width: '100%',
                          })}
                          target="_blank"
                          variant="outlined"
                        >
                          <Typography variant="inherit">Edit</Typography>
                        </Button>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <PopupState variant="popover">
                          {(popupState) => (
                            <>
                              <IconButton
                                color="primary"
                                variant="contained"
                                {...bindTrigger(popupState)}
                                size="large"
                              >
                                <MoreVert />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={async () => {
                                    popupState.close();
                                    createInitialState(composedQuery);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    enqueueSnackbar('Testing integrations', {
                                      variant: 'info',
                                    });
                                    popupState.close();
                                    try {
                                      await getRequest(
                                        `/data-hub/data?queryId=${composedQuery.id}&sendMessage=true`,
                                        {
                                          arg: {},
                                        }
                                      );
                                      enqueueSnackbar(
                                        'Integration test successful',
                                        {
                                          variant: 'success',
                                        }
                                      );
                                    } catch (e) {
                                      enqueueSnackbar(
                                        'Error testing integrations',
                                        {
                                          variant: 'error',
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Test integrations
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    trigger({
                                      queryId: composedQuery.id,
                                    });

                                    popupState.close();

                                    setTimeout(async () => {
                                      const updatedDataSource =
                                        await updateDataSourceObject({
                                          id: selectedDataSource.id,
                                        });

                                      batch(() => {
                                        reduxDispatch({
                                          type: types.UPDATE_TEAM_DATA_SOURCE,
                                          dataSource: updatedDataSource,
                                          teamId: updatedDataSource.team.id,
                                        });
                                      });
                                    }, 500);
                                  }}
                                >
                                  {isMutating ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <>Delete</>
                                  )}
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {queryObjects?.length === 0 && (
            <Box sx={{ mt: 4 }}>
              <Box display="flex" justifyContent="center">
                <StackedBarChartTwoTone color="disabled" fontSize="large" />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
              >
                No Queries found
              </Typography>
              <Typography
                align="center"
                color="textSecondary"
                display="block"
                gutterBottom
                variant="caption"
              >
                Build a Query below
              </Typography>
            </Box>
          )}
        </DialogContent>

        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            mt: 2,
            borderTop: `1px solid ${theme.palette.action.disabled}`,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              pt: 2,
            }}
          >
            <Button
              color="info"
              onClick={() => {
                createInitialState(orState);
                setOpen(true);
              }}
              startIcon={<Add />}
              variant="outlined"
            >
              Create Query
            </Button>
          </Box>
        </Box>

        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QueryComposerDialog;
