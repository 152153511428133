/* global  google */

/* eslint-disable no-nested-ternary */
import { useTheme } from '@emotion/react';
import { PersonAddTwoTone, ReportProblem } from '@mui/icons-material';
import { Avatar, Box, Button, ButtonBase, Link } from '@mui/material';
import { darken } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import querystring from 'querystring';
import React from 'react';
import { OauthSender } from 'react-oauth-flow';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import signInCallback from '../../auth/signInCallback';

import * as types from '../../constants/ActionTypes';
import * as cs from '../../constants/CredentialStatus';
import * as sc from '../../constants/Services';

function generateState(service, workspaceId) {
  let mode = '';
  if (window.location.hostname === 'localhost') {
    mode = 'local';
  } else if (window.location.hostname.indexOf('staging') > -1) {
    mode = 'staging';
  } else {
    mode = 'production';
  }

  return {
    dataProvider: service,
    workspaceId,
    mode,
    redirectUri: window.location.href,
  };
}

const redirectURL = `https://datahubapp.gladior.com/usercallback`;

function DefaultButton(props) {
  const { label, authButton, image, name, service, ...other } = props;

  return (
    <Button
      size="small"
      sx={(theme) => ({
        background: theme.palette.background.paper,
        textTransform: 'none',
      })}
      variant="outlined"
      {...other}
    >
      {label}
    </Button>
  );
}

function ErrorButton(props) {
  const { label, authButton, image, name, service, ...other } = props;

  return (
    <Button
      color="error"
      size="small"
      startIcon={<ReportProblem />}
      sx={(theme) => ({
        background: theme.palette.background.paper,
        textTransform: 'none',
      })}
      variant="outlined"
      {...other}
    >
      {label}
    </Button>
  );
}

function LargeButton(props) {
  const theme2 = useTheme();

  const { label, authButton, image, name, service, ...other } = props;

  const pColor = authButton?.color
    ? authButton.color
    : theme2.palette.common.white;

  if (service === sc.NO_OAUTH) {
    return (
      <Button
        color="secondary"
        size="large"
        startIcon={<PersonAddTwoTone />}
        variant="outlined"
        {...other}
      >
        {label || 'Add Credential'}
      </Button>
    );
  }

  if (authButton && authButton?.image) {
    return (
      <ButtonBase color="secondary" variant="outlined" {...other}>
        <Box
          sx={{
            width: authButton.width,
            height: authButton.height,
            backgroundSize: 'cover',
            backgroundPosition: 'center 40%',
            backgroundImage: `url(${authButton.image})`,
          }}
        />
      </ButtonBase>
    );
  }

  return (
    <Button
      color="inherit"
      size="large"
      startIcon={
        <Avatar
          src={image}
          sx={{
            width: 24,
            height: 24,
          }}
          variant="square"
        />
      }
      sx={(theme) => ({
        color: theme.palette.getContrastText(pColor),
        bgcolor: pColor,
        border: `1px solid ${theme.palette.action.disabled}`,
        textTransform: 'none',

        '&:hover': {
          bgcolor: darken(pColor, 0.4),
        },
      })}
      variant="contained"
      {...other}
    >
      {authButton?.label ? authButton.label : `Sign in with ${name}`}
    </Button>
  );
}

function CredentialPickerButton(props) {
  const {
    label,
    authButton,
    image,
    name,
    service,
    forceShowIcon = false,
    ...other
  } = props;
  return (
    <Button
      color="secondary"
      startIcon={
        forceShowIcon === false ? (
          <PersonAddTwoTone />
        ) : (
          <Avatar src={image} sx={{ height: 20, width: 20 }} />
        )
      }
      sx={(theme) => ({
        background: theme.palette.background.paper,
        textTransform: 'none',
      })}
      variant="outlined"
      {...other}
    >
      {label}
    </Button>
  );
}

function DefaultLink(props) {
  const { label, authButton, image, name, service, ...other } = props;
  return (
    <Link color="inherit" sx={{ cursor: 'pointer' }} {...other}>
      {label}
    </Link>
  );
}

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    }
  });

function AddCredentialButton({
  variant = 'default',
  reAuthenticate = false,
  selectedDataProvider,
  email = '',
  forceShowIcon = false,
}) {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [gclient, setGclient] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);
  const dataProviderSettings = useSelector(
    (state) => state.dataProviderSettings
  );

  const dpConf =
    dataProviderSettings?.config?.[selectedDataProvider?.toUpperCase()];
  const {
    service,
    authorizeUrl,
    additionalProperties,
    clientId,
    authButton,
    image,
    name,
    scopes,
    scopeSeparator = ',',
  } = dataProviderSettings?.config?.[selectedDataProvider] ?? {};

  async function googleinitCodeClientCallback(authResult) {
    const newCredential = await signInCallback(
      authResult,
      [selectedDataProvider],
      clientId,
      selectedUserTeam,
      dpConf
    );

    const { status } = newCredential;

    batch(() => {
      dispatch({
        type:
          status === cs.NEW
            ? types.ADD_TEAM_CREDENTIAL
            : types.UPDATE_TEAM_CREDENTIAL,
        credential: newCredential,
        movedCount: status === cs.NEW ? 1 : 0,
        teamId: newCredential.credentialTeamId,
      });
    });

    if (status === cs.UPDATED) {
      enqueueSnackbar(`Successfully gained access to ${newCredential.email}`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else if (status === cs.UNCHANGED) {
      enqueueSnackbar(`${newCredential.email} was already linked`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }

    const queryObj = querystring.parse(location.search.slice(1));

    queryObj.dataProvider = selectedDataProvider.toLowerCase();

    navigate({
      ...location,
      search: querystring.stringify(queryObj),
    });

    window.location.reload();
  }

  function googleinitCodeClient(clientId2, scopes2, hint = '') {
    return new Promise((resolve) => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: clientId2,
        scope: `openid email profile ${scopes2}`,
        ux_mode: 'popup',
        select_account: hint === '',
        hint,
        callback: googleinitCodeClientCallback,
      });

      resolve(client);
    });
  }

  React.useEffect(() => {
    async function main() {
      const src = 'https://accounts.google.com/gsi/client';

      await loadScript(src);

      const googleClient = await googleinitCodeClient(
        clientId,
        scopes.join(' '),
        email
      );

      setGclient(googleClient);
    }

    if (service === sc.GOOGLE) {
      main();
    }
  }, [service]);

  async function addNewGoogleCredential() {
    gclient.requestCode();
  }

  let addAccountLabel = dataProviderSettings.config[selectedDataProvider]
    .addAccountLabel
    ? dataProviderSettings.config[selectedDataProvider].addAccountLabel
    : 'Add Credential';

  if (reAuthenticate) {
    addAccountLabel = `Re-authenticate`;
  }

  const majesticLink = `https://majestic.com/account/login?redirect=${encodeURIComponent(
    `apps/MGJ3FH4A?return-url=${
      window.location.origin
    }/usercallback-majestic/%3Fcode={{AccessToken}}%26service=${selectedDataProvider}%26workspaceId=${
      selectedUserTeam.split('-')[0]
    }%26redirectUri=${`/workspace/${
      selectedUserTeam.split('-')[0]
    }/data-sources/create/data-source?dataProvider=MAJESTIC`}`
  )}`;

  const args = {};

  if (reAuthenticate === true && email) {
    args.login_hint = email;
  }
  if (additionalProperties) {
    Object.keys(additionalProperties).forEach((key) => {
      args[key] = additionalProperties[key];
    });
  }
  function noAuthOpener() {
    batch(() => {
      dispatch({
        type: types.SET_SELECTED_DATA_PROVIDER,
        dataProvider: selectedDataProvider,
      });

      dispatch({
        type: types.SHOW_USER_TOKEN_DIALOG,
        isVisible: true,
      });
    });
  }

  let ButtonType;

  switch (variant) {
    case 'default':
      ButtonType = DefaultButton;
      break;
    case 'error':
      ButtonType = ErrorButton;
      break;

    case 'credentialPicker':
      ButtonType = CredentialPickerButton;
      break;

    case 'large':
      ButtonType = LargeButton;
      break;

    case 'link':
      ButtonType = DefaultLink;
      break;
    default:
      ButtonType = DefaultButton;
      break;
  }

  return (
    <>
      {service === sc.GOOGLE && (
        <ButtonType
          authButton={authButton}
          className={'g_id_signin'}
          image={image}
          label={addAccountLabel}
          name={name}
          onClick={addNewGoogleCredential}
          service={service}
        />
      )}

      {service === sc.OTHER && (
        <OauthSender
          args={args}
          authorizeUrl={authorizeUrl}
          clientId={clientId}
          redirectUri={redirectURL}
          render={({ url }) => {
            const hrefButton = `${url}&scope=${scopes.join(scopeSeparator)}`;
            return (
              <ButtonType
                authButton={authButton}
                href={hrefButton}
                image={image}
                label={addAccountLabel}
                name={name}
                service={service}
              />
            );
          }}
          state={generateState(
            selectedDataProvider,
            selectedUserTeam.split('-')[0]
          )}
        />
      )}

      {service === sc.MAJESTIC && (
        <ButtonType
          authButton={authButton}
          href={majesticLink}
          image={image}
          label={addAccountLabel}
          name={name}
          service={service}
        />
      )}

      {service === sc.NO_OAUTH && (
        <ButtonType
          authButton={authButton}
          forceShowIcon={forceShowIcon}
          image={image}
          label={addAccountLabel}
          name={name}
          onClick={noAuthOpener}
          service={service}
        />
      )}
    </>
  );
}

export default AddCredentialButton;
